/**
 * author yangming
 * @param 将带标签的html内容转换成string字符串
 * @param {string} content : 需要处理的带<>的字符串
 * @param {number} num : 需要截取的字符串长度（默认为空）
 * @param return 截取之后的字符串
 * @param createTime:2019/3/4
 *
 * @param isEn : 判断是否为英文段落截取
 */
export function htmltoStr(content, num = 10000, isEn = false) {
  if (content === null) return '';
  // eslint-disable-next-line no-param-reassign
  if (!content) content = '';
  let quot = content
    .replace(/&rdquo;/g, '”')
    .replace(/&ldquo;/g, '“')
    .replace(/&quot;/g, '"')
    .replace(/<[^>]+>|&[^>]+;/g, '')
    .trim();
  // 处理文本中的HTML标签
  const s = quot.replace(/<[^>]+>/g, '').trim();
  // s为空字符串
  if (s === '') return '';
  // 判断是否为英文段落截取
  if (isEn) {
    const str1 = content.replace(/<[^>]+>|&[^>]+;/g, '').trim();
    if (str1.length < num) return str1;
    // 截取最后一个空格前的段落（段尾保留完整的英文单词）
    const text = str1.substr(0, Number(num));
    const n = text.lastIndexOf(' ');
    return `${text.substr(0, n)}...`;
  }
  // const t = s.replace(/ /g, '');

  if (s.length < num) {
    // 如果文字数量少于num，则直接返回
    return `${s}`;
  } else {
    // 需要截断文本长度
    if (num > 0) return `${s.substr(0, Number(num))}...`;
    return `${s}...`;
  }
}

/**
 * @param 处理文本中的HTML标签
 * @param {string} str
 */
export function deleteHtmlTag(str) {
  const str1 = str && str.replace(/<[^>]+>|&[^>]+;/g, '').trim();
  return str1;
}

/**
 * 获取html字符串中第一张图片的地址
 * @param {String} 需要处理的文本
 * @return {String} 处理的结果
 */
export function getFirstImg(content) {
  if (!content) return '';
  //   匹配图片
  const imgReg = /<img.*?(?:>|\/>)/gi;
  //   匹配src
  const srcReg = /src=['"]?([^'"]*)['"]?/i;
  const arr = content.match(imgReg);
  if (arr === null || arr.length < 0) return '';
  //   获取第一张图片的src
  const src = arr[0].match(srcReg);
  return src[1];
}

/**
 * 节流
 *
 * @export
 * @param {*} func
 * @param {number} [time=17]
 * @param {boolean} [options={ leading: true, trailing: false, context: null }]
 * @returns
 */
export function throttle(
  func,
  time = 17,
  options = { leading: true, trailing: false, context: null }
) {
  let previous = new Date(0).getTime();
  let timer;
  const _throttle = function(...args) {
    let now = new Date().getTime();
    if (!options.leading) {
      if (timer) return;
      timer = setTimeout(() => {
        timer = null;
        func.apply(options.context, args);
      }, time);
    } else if (now - previous > time) {
      func.apply(options.context, args);
      previous = now;
    } else if (options.trailing) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(options.context, args);
      }, time);
    }
  };
  _throttle.cancel = () => {
    previous = 0;
    clearTimeout(timer);
    timer = null;
  };
  return _throttle;
}

export function debounce(func, time = 16, options = { leading: true, trailing: true }) {
  let timer;
  let result;
  let context;
  let args;
  let now;
  let later = function() {
    let last = new Date().getTime() - now;
    if (last < time && last >= 0) {
      timer = setTimeout(later, time - last);
    } else {
      timer = null;
      if (!options.leading || (options.leading && options.trailing)) {
        result = func.apply(context, args);
        // 释放变量
        if (!timer) context = args = null;
      }
    }
  };
  const _debounce = function() {
    context = this;
    args = arguments;
    now = new Date().getTime();
    // 第一次调用该方法时，且immediate为true，则调用func函数
    let callNow = options.leading && !timer;
    // 第一次赋值timer
    if (!timer) timer = setTimeout(later, time);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
  _debounce.cancel = function() {
    clearTimeout(timer);
    timer = context = null;
  };
  return _debounce;
}

/**
 * 返回传入值的类型
 *
 * @export
 * @param {*} obj
 * @returns
 */
export function type(obj) {
  return Object.prototype.toString
    .call(obj)
    .replace(/^\[object (.+)\]$/, '$1')
    .toLowerCase();
}

export function browserInfo() {
  const UserAgent = navigator.userAgent.toLowerCase();
  let browser = null;

  const browserArray = {
    IE: window.ActiveXObject || 'ActiveXObject' in window, // IE
    Chrome: UserAgent.indexOf('chrome') > -1 && UserAgent.indexOf('safari') > -1, // Chrome浏览器
    Firefox: UserAgent.indexOf('firefox') > -1, // 火狐浏览器
    Opera: UserAgent.indexOf('opera') > -1, // Opera浏览器
    Safari: UserAgent.indexOf('safari') > -1 && UserAgent.indexOf('chrome') === -1, // safari浏览器
    Edge: UserAgent.indexOf('edge') > -1, // Edge浏览器
    QQBrowser: /qqbrowser/.test(UserAgent), // qq浏览器
    WeixinBrowser: /MicroMessenger/i.test(UserAgent) // 微信浏览器
  };
  for (let i in browserArray) {
    if (browserArray[i]) {
      browser = i;
    }
  }
  return browser;
}
export function getOperationSys() {
  let OS = 'Unknown';
  let OSArray = {};
  const UserAgent = navigator.userAgent.toLowerCase();
  const platform = navigator.platform.toLowerCase();
  OSArray.Windows = platform === 'win32' || platform === 'windows';
  OSArray.Mac =
    platform === 'mac68k' ||
    platform === 'macpcc' ||
    platform === 'macintosh' ||
    platform === 'macintel';
  OSArray.iphone = UserAgent.indexOf('iphone') > -1;
  OSArray.ipod = UserAgent.indexOf('ipod') > -1;
  OSArray.ipad = UserAgent.indexOf('ipad') > -1;
  OSArray.Android = UserAgent.indexOf('android') > -1;
  for (let i in OSArray) {
    if (OSArray[i]) {
      OS = i;
    }
  }
  return OS;
}

/**
 * 根据day获取星期
 * @export
 * @param {*} day 星期数
 * @param {*} type 类型(默认0) 0:周日 1:星期天 2:Sunday 3:Sun
 * @returns
 */
export function getWeek(day, type) {
  if (!type) {
    // eslint-disable-next-line no-param-reassign
    type = 0;
  }
  let weekday = [
    ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
    ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
    ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat']
  ];
  return weekday[type][day];
}

/**
 * 获取显示日期，格式为：2015年3月14日（周六）10:00-11:30
 *
 * @export
 * @param {*} StartTime 开始时间
 * @param {*} EndTime   结束时间
 * @param {*} ishasWeek 是否显示星期 默认显示
 * @returns
 */
export function showDateTime(StartTime, EndTime, ishasWeek) {
  if (StartTime === '' || EndTime === '' || StartTime === null || EndTime === null) {
    return '';
  }

  let SatrtTimeTemp = '';
  let startYear;
  let startMonth;
  let startDay;
  let startWeek;
  let startTiming;
  let endYear;
  let endMonth;
  let endDay;
  let endWeek;
  let endTiming;
  // 获取开始年份
  startYear = new Date(StartTime).getFullYear();
  // 获取开始月份
  startMonth = new Date(StartTime).getMonth() + 1;
  // 获取开始日期
  startDay = new Date(StartTime).getDate();
  // 获取星期
  startWeek = ishasWeek ? `（${getWeek(new Date(StartTime).getDay())}）` : '';
  // 获取时间
  startTiming = StartTime.split(' ')[1] ? StartTime.split(' ')[1] : '';
  // 获取截止年份
  endYear = new Date(EndTime).getFullYear();
  // 获取截止月份
  endMonth = new Date(EndTime).getMonth() + 1;
  // 获取截止日期
  endDay = new Date(EndTime).getDate();
  // 获取截至星期
  endWeek = ishasWeek ? `（${getWeek(new Date(EndTime).getDay())}）` : '';
  // 获取截至时间
  endTiming = EndTime.split(' ')[1] ? EndTime.split(' ')[1] : '';

  SatrtTimeTemp += `${startYear}年${startMonth}月${startDay}日${startWeek}${startTiming}`;

  if (!(StartTime.split(' ')[0] === EndTime.split(' ')[0] && !startTiming)) {
    SatrtTimeTemp += '~';
  }

  if (startYear !== endYear) {
    SatrtTimeTemp += `${endYear}年${endMonth}月${endDay}日${endWeek}${endTiming}`;
  } else {
    if (startMonth !== endMonth) {
      SatrtTimeTemp += `${endMonth}月${endDay}日${endWeek}${endTiming}`;
    } else {
      if (startDay !== endDay) {
        SatrtTimeTemp += `${endDay}日${endWeek}${endTiming}`;
      } else {
        SatrtTimeTemp += `${endTiming}`;
      }
    }
  }
  return SatrtTimeTemp;
}

export function getYearList() {
  const date = new Date();
  const firstYear = date.getFullYear();
  let year = firstYear;
  const arr = [];

  arr.push({ lable: year, value: year });

  while (firstYear - year < 9) {
    year--;
    firstYear - year !== 9
      ? arr.push({ lable: year, value: year })
      : arr.push({ lable: year + '年以前', value: year });
  }
  return arr;
}

export function handleYear(year, isEnd) {
  const start = !isEnd ? year + '-01-01' : '2010-01-01';
  const end = year + '-12-31';
  return start + '#' + end;
}

// 获取大事记年份
export function getEventsYear(count, lastyear) {
  const myDate = new Date();
  const tYear = myDate.getFullYear();

  let arr = [];
  for (let i = tYear; i >= lastyear; i--) {
    arr.push(i);
  }

  //   生成count个一组的数组
  let spArray = function(N, Q) {
    let R = [];
    let F;
    for (F = 0; F < Q.length; ) {
      R.push(Q.slice(F, (F += N)));
    }
    return R;
  };

  return spArray(count, arr);
}

// 根据年份获取对应内容
export function getEventsDataByYear(year, cont) {
  let data = cont;
  let month1 = 0;
  let month2 = 0;
  if (data.length > 0) {
    data = data.filter((item) => {
      return parseInt(item.publishDate.split('-')[0], 10) === year;
    });
    data = data.reverse();
    data.map((item, index) => {
      if (index === data.length - 1) return '';
      month1 = item.publishDate.split('-')[1];
      month2 = data[index + 1].publishDate.split('-')[1];
      if (month1 === month2) {
        data[index + 1].hideDate = true;
      }
      return item;
    });
  }
  return data;
}

// 取推荐内容 type为字段名
export function getRecommend(data, type) {
  let isrecomm;
  for (let i = 0; i < data.length; i++) {
    if (isrecomm) return;
    if (data[i].$raw_data.flag.indexOf('n') > 0) {
      isrecomm = true;
      return type ? data[i][type] : data[i];
    }
  }
  // 如果没有推荐，则读第一条
  if (!isrecomm) {
    return type ? data[0][type] : data[0];
  }
}

export function changePictureSize(path, width) {
  // THUMB
  let pa = path.split('/');
  pa.splice(-1, 0, 'THUMB');
  let imgpath = pa.pop().split('.');
  imgpath.splice(1, 0, `_${width}@x.`);
  pa.push(imgpath.join(''));
  return pa.join('/');
}

/**
 * @public
 * @description 将publishdate拿到对应的年月日以及英文月份
 * @param {string} key - publicDate
 * @returns {any} 年月日对象
 */
let en_mon_arr = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Spt',
  'Oct',
  'Nov',
  'Dec'
]; //  英文月份
let cn_mon_arr = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二']; //  中文月份
export function dealPubishDate(data) {
  let arr = data.split('-');
  let index = Number(arr[1]) - 1;
  let obj = {
    year: arr[0],
    month: arr[1],
    date: arr[2],
    enMonth: en_mon_arr[index],
    cnMonth: cn_mon_arr[index]
  };
  return obj;
}

/**
 * @description 生成全局唯一uuid
 * @param {Number} len uuid长度
 * @param {Number} radix uuid基数
 */
export function uuid(len, radix) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid = [];
  let i;
  let rad = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * rad)];
  } else {
    // rfc4122, version 4 form
    let r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        // eslint-disable-next-line
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join('');
}
