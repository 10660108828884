/**
 * @file   总页面
 * @create
 * @update
 */
import 'regenerator-runtime/runtime.js';
import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { PortalSite } from '@portal-site/core';
import { routesMap } from '@portal-site/data';
import { createBrowserHistory } from 'history';
import { PortalUIProvider } from '@portal-site/components';
import Header from './common/Header';
import InnerBanner from './common/Header/InnerBanner';
import Footer from './common/Footer';
import { pcRoutes } from './routes';
import 'normalize.css';
import SideBar from './common/SideBar';
// import 'viewerjs/dist/viewer.min.css';
// import SearchPage from './common/Search';
import NoMatch from '../components/NoMatch';
import { dataProvider } from './service/dataProvider';
import { PagerConfig } from '@portal-site/pager';
import { ReactComponent as NextIcon } from '../../assets/svgs/next.svg';
import { RecoilRoot } from 'recoil';
import { http } from './service/http';
import defaultImg from '../../assets/images/default.jpg';

const ENV_IMG_PREURL = window.ENV_IMG_PREURL;
const history = createBrowserHistory();

const App = ({ children }) => {
  return (
    <RecoilRoot>
      <PortalSite
        models={[routesMap]}
        dataProvider={dataProvider(http)}
        history={history}
        root="cn"
      >
        <PortalUIProvider
          value={{
            assetsPrefix: ENV_IMG_PREURL,
            pictureFallback: defaultImg
          }}
        >
          <PagerConfig.Provider
            value={{
              prevLabel: <NextIcon transform="rotate(180)" />,
              nextLabel: <NextIcon />,
              showJump: true,
              showTotal: true,
              containerClassName: 'mypager'
            }}
          >
            <Page />
          </PagerConfig.Provider>
        </PortalUIProvider>
      </PortalSite>
    </RecoilRoot>
  );
};
const Page = () => {
  const location = useLocation();
  const isIndex = location.pathname === '/' || location.pathname === '/Home';
  const channelNo = location.pathname.split('/')[1];

  return (
    <>
      <Header isIndex={isIndex} channelNo={channelNo} />
      <SideBar />
      <div className={channelNo !== 'News' ? 'index-other-cont' : 'index-newscont'}>
        {isIndex ? (
          <div className="index-cont">
            <Switch>
              {pcRoutes.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                  />
                );
              })}
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </div>
        ) : (
          <>
            {channelNo !== 'News' ? <InnerBanner /> : null}
            <div className={`inner-cont ${channelNo.toLowerCase()}-cont`}>
              <Switch>
                {pcRoutes.map((route) => {
                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  );
                })}
                <Route path="*">
                  <NoMatch />
                </Route>
              </Switch>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};
export default App;
