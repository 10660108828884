import {
  listFilter,
  detailFilter,
  fileFilter,
  exhListFilter,
  exhBaseListFilter,
  exhDetailFilter,
  colListFilter,
  colDetailFilter,
  actListFilter,
  actDetailFilter,
  exhBaseDetailFilter,
  exhibitionTypeFilter,
  searchListFilter,
  leaveWordListFilter
} from './filter';
import { cms_gateway, FILEGATEWAY } from './http';

function dataProvider(http) {
  const baseProvider = {
    /**
     * @kind javaapi
     * @description 根据文章Id查询详情
     * @param {string} id  - 文章iD
     */
    async queryArchivesById(id) {
      // const res = await http.get(`/japi/${cms_gateway}/api/queryArchivesById/${id}`);
      const res = await http.get(`/${cms_gateway}/api/queryArchivesById/${id}`);
      const data = res.data.data;
      return {
        records: detailFilter(data)
      };
    },
    /**
     * @kind javaapi
     * @description 根据栏目编号查询详情
     * @param {string} no - 栏目编号
     */
    async queryArchivesByNo(no) {
      const res = await http.post(`/${cms_gateway}/api/queryArchivesByNo`, {
        channelNo: no
      });
      const data = res.data.data;
      return {
        records: detailFilter(data)
      };
    },
    /**
     * @kind javaapi
     * @description 查询文章列表
     * @param {Object} options - 查询参数
     */
    async queryArchivesList(options = { pagination: {}, resource: '', params: {} }) {
      const { pagination, params, resource } = options;
      if (!resource) {
        return {
          records: [],
          pages: 0,
          page: 0,
          total: 0,
          $$rawData: {}
        };
      }

      const res = await http.post(`/${cms_gateway}/api/queryArchivesList`, {
        entity: {
          channelNo: resource,
          ...params
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: listFilter(data?.records ?? []),
        pages: data?.pages,
        page: data?.current,
        total: data?.total,
        $$rawData: data
      };
    },

    /**
     * @kind javaapi
     * @description 查询多个栏目文章列表，resource用,隔开
     * @param {Object} options - 查询参数
     */
    async queryArchivesListByMulNo(options = { pagination: {}, resource: '', params: {} }) {
      const { pagination, params, resource } = options;
      if (!resource)
        return {
          records: [],
          pages: 0,
          page: 0,
          total: 0,
          $$rawData: {}
        };
      const res = await http.post(`/japi/${cms_gateway}/api/queryArchivesListByMulNo`, {
        entity: {
          channelNo: resource,
          ...params
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: listFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },

    /**
     * @kind javaapi
     * @description 获取常展列表信息
     * @param {Object} options
     */
    async queryExhibitionBaseList(options = { pagination: {}, params: {} }) {
      const { pagination } = options;
      const res = await http.post(`/japi/${cms_gateway}/api/queryExhibitionBaseList`, {
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: exhBaseListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    /**
     * @kind javaapi
     * @description 查询常展详情
     * @param {string} id
     */
    async queryExhibitionBaseById(id) {
      const res = await http.get(`/japi/${cms_gateway}/api/queryExhibitionBaseById/${id}`);
      const data = res.data.data;
      return {
        records: exhBaseDetailFilter(data),
        $$rawData: data
      };
    },
    /**
     * @kind javaapi
     * @description 查询临展列表
     * @param {Object}} options
     */
    async queryExhibitionTempList(options = { pagination: {}, params: {}, searchParams: {} }) {
      const { pagination, params = {}, searchParams } = options;
      const res = await http.post(`/japi/${cms_gateway}/api/queryExhibitionTempList`, {
        entity: {
          isRecommend: params.isRecommend,
          ...searchParams
        },
        param: {
          type: params.type, // 0:未开始,1:未开始+进行中,2:已结束,3:进行中
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: exhListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    /**
     * @kind javaapi
     * @description 查询临战详情
     * @param {string} id
     */
    async queryExhibitionTempById(id) {
      const res = await http.get(`/japi/${cms_gateway}/api/queryExhibitionTempById/${id}`);
      const data = res.data.data;
      return {
        data: exhDetailFilter(data),
        $$rawData: data
      };
    },
    /**
     * @kind javaapi
     * @description 展品列表
     * @param {Object} options
     */
    async queryExhibitList(options = { pagination: {}, params: {} }) {
      try {
        const { pagination, params = {} } = options;
        const res = await http.post(`/japi/${cms_gateway}/api/queryExhibitList`, {
          entity: {
            exhibitLevel: params.level,
            exhibitName: params.keyword,
            boolean1: params.boolean1,
            dictionary1: params.type,
            modelUrl: params.modelUrl,
            orgId: params.orgId,
            isExpMp3: params.isExpMp3 // 是否有语音，是为true
          },
          param: {
            pageSize: pagination.size ?? 12,
            pageNum: pagination.page ?? 1
          }
        });
        const data = res.data.data;
        return {
          records: colListFilter(data.records),
          pages: data.pages,
          page: data.current,
          total: data.total,
          $$rawData: data
        };
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * @kind javaapi
     * @description 展品详情
     * @param {Object} id
     */
    async queryExhibitById(id) {
      const res = await http.get(`/japi/${cms_gateway}/api/queryExhibitById/${id}`);
      const data = res.data.data;
      return {
        records: colDetailFilter(data),
        $$rawData: data
      };
    },
    /**
     * @kind javaapi
     * @description 关联展品
     * @param {Object} options
     */
    async queryExhibitListByTemp(options = { pagination: {}, params: {} }) {
      const { pagination, params = {} } = options;
      const res = await http.post(`/japi/${cms_gateway}/api/queryExhibitListByTemp`, {
        entity: {
          groupId: params.groupId
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: colListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    /**
     * @kind javaapi
     * @description 展品类型
     */
    async queryExhibitTypeList() {
      const res = await http.post(`/japi/${cms_gateway}/api/queryExhibitTypeList`, {
        entity: {},
        param: {
          pageSize: 0,
          pageNum: 0
        }
      });
      const data = res.data.data;
      return {
        records: exhibitionTypeFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    /**
     * @kind javaapi
     * @description 查询活动列表
     * @param {Object} options
     */
    async queryActivityListByType(_options) {
      const options = {
        ...{ pagination: {}, resource: '', entityParams: {}, params: {} },
        ..._options
      };
      const { pagination, params, resource, entityParams } = options;
      try {
        const res = await http.post(`/japi/${cms_gateway}/api/queryActivityListByType`, {
          entity: {
            activityType: resource,
            ...entityParams
          },
          param: {
            // type: params.param.type, 正在1,回顾2
            pageSize: pagination.size,
            pageNum: pagination.page,
            ...params
          }
        });
        const data = res.data.data;
        return {
          records: actListFilter(data.records),
          pages: data.pages,
          page: data.current,
          total: data.total,
          $$rawData: data
        };
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * @kind javaapi
     * @description 查询活动详情
     * @param {Object} id
     */
    async queryActivityById(id) {
      const res = await http.get(`/japi/${cms_gateway}/api/queryActivityById/${id}`);
      const data = res.data.data;
      return {
        records: actDetailFilter(data),
        $$rawData: data
      };
    },
    /**
     * @kind middle_api
     * @description 附件
     * @param {Object} options
     */
    async queryFileList(options = { pagination: {}, params: {} }) {
      const result = {
        records: [],
        pages: 0,
        page: 0,
        total: 0,
        $$rawData: {}
      };
      if (!options.params.groupId) return result;

      const { pagination, params } = options;

      const res = await http.post(`/japi/${FILEGATEWAY}/refFileObject/queryPageList`, {
        entity: {
          objectId: params.groupId, // 关联id
          code: params.code ? params.code : '' // 文件类型
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: fileFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    // 全文检索
    async searchAll(options = { pagination: {}, params: {} }) {
      const result = {
        records: [],
        pages: 0,
        page: 0,
        total: 0,
        $$rawData: {}
      };
      if (!options.params.keywords) return result;

      const { pagination, params } = options;

      const res = await http.post('/api/searchAll', {
        clusterName: params.clusterName,
        pageNum: pagination.page,
        pageSize: pagination.size,
        searchValue: params.keywords,
        searchMap: {
          type: params.type
        }
      });
      const data = res.data.data;
      return {
        records: searchListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },

    /**
     * @kind javaapi
     * @description 留言列表
     * @param {Object} options
     */
    async queryMessageList(options = { pagination: {}, params: {} }) {
      const { pagination, params = {} } = options;
      const res = await http.post(`/japi/${cms_gateway}/personalapi/queryMessageList`, {
        entity: {
          ...params
        },
        param: {
          pageSize: pagination.size,
          pageNum: pagination.page
        }
      });
      const data = res.data.data;
      return {
        records: leaveWordListFilter(data.records),
        pages: data.pages,
        page: data.current,
        total: data.total,
        $$rawData: data
      };
    },
    /**
     * @kind javaapi
     * @description 添加留言
     * @param {Object} options
     */
    async addMessage(options) {
      const { content, name, phoneNumber, email, type, isAnonymous, source } = options;
      const res = await http.post(`/japi/${cms_gateway}/personalapi/addMessage`, {
        content,
        name,
        phoneNumber,
        email,
        type,
        isAnonymous,
        source
      });
      const data = res.data;
      return {
        data: data.data,
        msg: data.msg,
        code: data.code
      };
    }
  };
  const enhanceProvider = {
    async queryColDetail(options = { pagination: {}, params: {} }) {
      const { pagination, params } = options;
      const a = {
        pagination,
        params: {
          groupId: params.groupId,
          code: 'CMS_EXHIBIT_INFO_DISPLAY'
        }
      };
      const b = params.id;
      return Promise.all([baseProvider.queryFileList(a), baseProvider.queryExhibitById(b)]);
    }
  };
  return { ...baseProvider, ...enhanceProvider };
}

export default dataProvider;
