/**
 * @file   网站头部
 * @create lixiang 2021/04/19
 * @update
 */
import React, { useMemo, useCallback, useRef, useEffect } from 'react';
import './index.scss';
import { useRoutesMap } from '@portal-site/core';
import { NavLink, Link } from 'react-router-dom';
import { css, cx } from 'emotion';
import { debounce } from '../../../../utils';
// 获取栏目
export function Nav() {
  const routes = useRoutesMap();
  const cl = useCallback((_, location, to) => {
    return location.pathname.split('/').includes(to);
  }, []);
  const exclude = useMemo(() => {
    const flat = routes.flat();
    return flat.filter((i) => !i.isVisible).map((i) => i.route);
  }, [routes]);
  return <NavComp routes={routes} exclude={exclude} isActive={cl} />;
}

// 栏目
const NavComp = ({ routes, exclude }) => {
  return (
    <nav className={cx(css``, 'portal-nav')}>
      <ul
        className={cx(
          css({
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            height: '100%'
          }),
          'portal-nav-firstmenu'
        )}
      >
        {routes.children
          .filter((item) => !exclude.includes(item.route))
          .map((route) => {
            return (
              <li
                className={cx(
                  css`
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 80px;
                    flex-wrap: wrap;
                    & > a {
                      width: 100%;
                      text-align: center;
                    }
                    &:hover {
                      nav {
                        display: flex;
                      }
                    }
                  `,
                  'portal-nav-firstmenu-li'
                )}
                key={route.name}
              >
                {route.url.indexOf('http') !== -1 ? (
                  <a href={route.url} target="_blank" rel="noopener noreferrer">
                    {route.name}
                  </a>
                ) : (
                  <NavLink to={route.url !== '/' ? route.url : route.path}>{route.name}</NavLink>
                )}
                <nav
                  className={cx(
                    css`
                      position: absolute;
                      top: 80px;
                      display: none;
                      justify-content: center;
                    `,
                    'portal-nav-second'
                  )}
                >
                  <ul className="portal-nav-secondmenu">
                    {route.children
                      .filter((item) => !exclude.includes(item.route))
                      .map((child) => {
                        return (
                          <li key={child.name}>
                            {child.url.indexOf('http') !== -1 ? (
                              <a href={child.url} target="_blank" rel="noopener noreferrer">
                                {child.name}
                              </a>
                            ) : (
                              <NavLink to={child.url !== '/' ? child.url : child.path}>
                                {child.name}
                              </NavLink>
                            )}
                          </li>
                        );
                      })}
                  </ul>
                </nav>
              </li>
            );
          })}
      </ul>
    </nav>
  );
};

// 栏目菜单
export const Headerbox = ({ noallcenter }) => {
  const menu = useRef();
  /*  const [active, setState] = useState(false);
  const routes = useRoutesMap();
  useEffect(() => {
    // 如果有值
    if (menu.current) {
      const li = menu.current.getElementsByClassName('portal-nav-firstmenu-li');
      for (let index = 0; index < li.length; index++) {
        // 当没有二级栏目时，不显示二级栏目背景条
        if (li[index].getElementsByTagName('li').length > 0) {
          li[index].onmouseover = function() {
            setState(true);
          };
          li[index].onmouseout = function() {
            setState(false);
          };
        } else {
          li[index].className = 'css-grjzz9 portal-nav-firstmenu-li nochild';
          li[index].onmouseover = function() {
            setState(false);
          };
        }
      }
    }
  }, [routes.children.length]); */

  return (
    <div className="headerbox" ref={menu}>
      <Nav />

      {/* <div className={active ? 'secmenu-bg active' : 'secmenu-bg'} /> */}
    </div>
  );
};

const Header = function() {
  const mainheader = useRef();

  const scroll = () => {
    const top = document.body.scrollTop || document.documentElement.scrollTop;
    const sc = mainheader.current;
    if (sc && top > 20) {
      sc.classList.add('active');
    } else {
      sc.classList.remove('active');
    }
  };
  const debounceScroll = debounce(scroll, 50);
  useEffect(() => {
    window.addEventListener('scroll', debounceScroll);
    return () => {
      window.removeEventListener('scroll', debounceScroll);
    };
  }, [debounceScroll]);

  return (
    <header className="mainheader" ref={mainheader}>
      <div className="allcenter">
        <Link to="/">
          <div className="logo" />
        </Link>
        <Headerbox />
      </div>
    </header>
  );
};
export default Header;
