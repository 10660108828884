/**
 * @file   网站侧边栏
 * @create lixiang 2020/10/26
 * @update zhangchen 2020/11/30 修改搜索跳转
 */
import React, { useEffect, useRef, useState } from 'react';
// import { Link } from 'react-router-dom';
import { debounce } from '../../../../utils';
import Layer from '../../../components/Layer';
// import DetailShare from '../DetailShare';
import './index.scss';

// 网站侧边栏
const SideBar = function() {
  const [openQuestion, setOpenQuestion] = useState(false);

  const toTop = useRef();
  const sidebar = useRef();

  const toTopHandleClick = () => {
    try {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  };

  const scroll = () => {
    const top = document.body.scrollTop || document.documentElement.scrollTop;
    const sc = sidebar.current;
    const tc = toTop.current;
    if (top > 150) {
      sc.style.opacity = 1;
      sc.style.pointerEvents = 'all';
    } else {
      sc.style.opacity = 0;
      sc.style.pointerEvents = 'none';
    }
    if (top > 350) {
      sc.classList.add('sidebar-a');
      tc.style.opacity = 1;
    } else {
      sc.classList.remove('sidebar-a');
      tc.style.opacity = 0;
    }
  };
  const debounceScroll = debounce(scroll, 50);
  useEffect(() => {
    window.addEventListener('scroll', debounceScroll);
    return () => {
      window.removeEventListener('scroll', debounceScroll);
    };
  }, [debounceScroll]);
  return (
    <>
      <ul className="sidebar" ref={sidebar}>
        {/*   <li>
          <Link to="/Search">
            <div className="box">
              <i className="iconfont iconsousuo" />
              <p>全文检索</p>
            </div>
          </Link>
        </li> */}
        {/*  <li>
        <Link to="/">
          <div className="box">
            <i className="iconfont icongeren" />
            <p>会员中心</p>
          </div>
        </Link>
      </li>
      <li>
        <Link to="/">
          <div className="box">
            <i className="iconfont iconyuding" />
            <p>展览日历</p>
          </div>
        </Link>
      </li>
        <li>
          <Link to="/Download">
            <div className="box">
              <i className="iconfont iconxiazai" />
              <p>文档下载</p>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/Leaveword/List">
            <div className="box">
              <i className="iconfont iconliuyan" />
              <p>我要留言</p>
            </div>
          </Link>
        </li>
        <li
          onClick={() => {
            setOpenQuestion(true);
          }}
        >
          <div className="box">
            <i className="iconfont iconwenjuan" />
            <p>问卷调查</p>
          </div>
        </li>
        <li>
          <Link to="/FriendLink">
            <div className="box">
              <i className="iconfont iconlianjie" />
              <p>友情链接</p>
            </div>
          </Link>
        </li>
        <li className="fx">
          <div className="box">
            <i className="iconfont iconfenxiang" />
            <p>网站分享</p>
          </div>
          <div className="leftbox">
            <DetailShare />
          </div>
        </li> */}
        <li ref={toTop} onClick={toTopHandleClick}>
          <div className="box">
            <i className="iconfont iconfanhuidingbu" />
            <p>返回顶部</p>
          </div>
        </li>
      </ul>
      <Layer
        containerStyle={{
          width: 260,
          height: 154
        }}
        width={500}
        visible={openQuestion}
        onClose={() => {
          setOpenQuestion(false);
        }}
      >
        <p>暂无问卷。</p>
      </Layer>
    </>
  );
};
export default SideBar;
