/**
 * @file   网站底部
 * @create lixiang 2021/04/21
 * @update
 */

import React from 'react';
import './index.scss';
import emw from '@/assets/images/desktop/common/ewm.png';
import ReactWOW from 'react-wow';
// 网站底部
function Footer() {
  return (
    <footer className="mainfooter" id='footer'>
      <div className="allcenter">
        <div className="leftbox">
          <ReactWOW animation="fadeInUp" duration="1.2s" delay="0.1s">
            <div>
              <p className="address">
                武汉<span>|</span>洪山区民族大道182号中南民族大学北区国家智慧文博新融合产业基地三楼
              </p>
            </div>
          </ReactWOW>
          <ReactWOW animation="fadeInUp" duration="1.2s" delay="0.2s">
            <p className="address">
              广州<span>|</span>越秀区建设六马路33号宜安广场2806室
            </p>
          </ReactWOW>
          <ReactWOW animation="fadeInUp" duration="1.2s" delay="0.3s">
            <p className="address">
              郑州<span>|</span>金水区文化路街道经三路68号2号楼招商银行大厦1309
            </p>
          </ReactWOW>
          <ReactWOW animation="fadeInUp" duration="1.2s" delay="0.4s">
            <p className="phone">
              商务
              <span>027-87698581</span>
            </p>
          </ReactWOW>
          <ReactWOW animation="fadeInUp" duration="1.2s" delay="0.5s">
            <p className="phone">
              行政
              <span>027-87680703</span>
            </p>
          </ReactWOW>
          <ReactWOW animation="fadeInUp" duration="1.2s" delay="0.6s">
            <p className="otherinfo">
              <a href="http://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
                鄂ICP备17015503号-1
              </a>
              鄂公网安备42011502000954号
            </p>
          </ReactWOW>
        </div>
        <ReactWOW animation="fadeInUp-little" duration="1.2s" delay="0.3s">
          <div className="ewmbox">
            <img src={emw} alt="" />
          </div>
        </ReactWOW>
      </div>
    </footer>
  );
}

export default Footer;
