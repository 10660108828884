/**
 * @file   路由
 * @create lixiang 2020/10/10
 */
import React from 'react';
import { Redirect } from 'react-router-dom';
import withScrollTo from '../../components/ScrollTo';
import {
  AsyncHome,
  AsyncProductsServices,
  AsyncSolution,
  AsyncAbout,
  AsyncNews,
  S_MainDetail
} from './routes';

const mainRoutes = [
  {
    path: '/',
    component: withScrollTo(AsyncHome),
    exact: true
  },
  {
    path: '/Home',
    component: withScrollTo(AsyncHome),
    exact: true
  },
  {
    path: '/About',
    component: withScrollTo(AsyncAbout),
    exact: true
  },
  {
    path: '/About/:channel',
    component: withScrollTo(AsyncAbout),
    exact: true
  },
  {
    path: '/Service',
    component: () => <Redirect to="/Service/GHZX" />,
    exact: true
  },
  {
    path: '/Service/:channel',
    component: withScrollTo(AsyncProductsServices),
    exact: true
  },
  {
    path: '/News',
    component: withScrollTo(AsyncNews),
    exact: true
  },
  {
    path: '/News/:id',
    component: withScrollTo(S_MainDetail),
    exact: true
  },
  {
    path: '/Solution',
    component: () => <Redirect to="/Solution/ZHWLY" />,
    exact: true
  },
  {
    path: '/Solution/:channel',
    component: withScrollTo(AsyncSolution),
    exact: true
  }
  /*  {
    path: '/FriendLink',
    component: withScrollTo(AsyncFriendLink),
    exact: true,
    name: '友情链接'
  } */
];

export const pcRoutes = mainRoutes;
