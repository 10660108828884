/**
 * @file   内页banner图
 * @create lixiang 2020/10/19
 * @update lixiang 2020/10/29 优化代码
 */
import React, { useEffect, useMemo } from 'react';
import { useRoutesMap } from '@portal-site/core';
import { useLocation } from 'react-router-dom';
import { getChannelNo } from '@/utils/platform';
import { Picture } from '@portal-site/components';

// 内页banner图
function InnerBanner() {
  const imgw = 1920;
  const imgh = 824;

  const allroutes = useRoutesMap().flatToObject();
  const { pathname } = useLocation();
  const memoChannels = useMemo(() => {
    return getChannelNo(pathname);
  }, [pathname]);

  const onChange = () => {
    const windoww = document.body.clientWidth;
    const currentImgh = (windoww * imgh) / imgw;
    document.getElementById('inner-banner').style.height = currentImgh + 'px';
    document.getElementById('innerbanner').style.height = currentImgh + 'px';
  };

  useEffect(() => {
    onChange();
    // 相当于 componentDidMount
    window.addEventListener('resize', onChange, false);
    return () => {
      // 相当于 componentWillUnmount
      window.removeEventListener('resize', onChange, false);
    };
  }, []);

  return (
    <div className="inner-banner" id="inner-banner">
      <div id="innerbanner" className="indexbanner">
        {allroutes[memoChannels[1]]?.imgSrc ? (
          <Picture source={allroutes[memoChannels[1]].imgSrc} />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default InnerBanner;
