/**
 * @file   http
 * @create wangdekui 2020/09/27
 * @update
 */
import { createHttpClient } from '@portal-site/data';
const ENV_API = window.ENV_API;
export const cms_gateway = 'sw-cms';
export const FILEGATEWAY = 'sw-file';
export const http = createHttpClient(
  ENV_API,
  '6be4aac772f444d99b580d28b693b80c',
  'OG4SO12KIF0HY4ZF5V0GHBS5A1BQ4XEX'
);

export const https = createHttpClient(ENV_API, '', '');

/* http.interceptors.request.use((config) => {
  const data = config.data;
  // const gatewayToken = localDS.get('gatewayToken');

  if (data) {
    if (typeof data === 'string') {
      try {
        const _data = JSON.parse(data);
        _data.portalId = ENV_PORTALID;
        config.data = JSON.stringify(_data);
      } catch (err) {
        console.log(err);
      }
    } else if (typeof data === 'object') {
      if (data.entity) {
        data.entity.portalId = ENV_PORTALID;
      } else {
        data.portalId = ENV_PORTALID;
      }
    } else {
      const data = config.data;
      data.append('portalId', ENV_PORTALID);
      config.data = data;
    }
  }

  return config;
}); */

https.interceptors.request.use((config) => {
  const data = config.data;

  if (data) {
    if (typeof data === 'string') {
      try {
        const _data = JSON.parse(data);
        config.data = JSON.stringify(_data);
      } catch (err) {
        console.log(err);
      }
    } else {
      const data = config.data;
      config.data = data;
    }
  }

  return config;
});
