import { getFirstImg, showDateTime } from '../../../utils';
import moment from 'moment';
export const html2text = (content, { length } = { length: 100, wordBreak: false }) => {
  const s = content?.replace(/<[^>]+>|&[^>]+;/g, '').trim();
  if (s === '' || s === undefined) return '';
  if (s.length <= 100) return s;
  return s.substr(0, length) + '...';
};

export const assetsPrefix = window.ENV_IMG_PREURL;

/**
 * 格式化列表
 *
 * @export
 * @param {*} data 元数据
 * @returns
 */
export function listFilter(data) {
  return data.map(detailFilter);
}

/**
 * 格式化详情
 *
 * @export
 * @param {*} item
 */
export function detailFilter(item) {
  return {
    $raw_data: item,
    id: item.archivesId, // 文章id
    channelNo: item.channelNo, // 栏目编号
    channelName: item.channelName, // 栏目名称
    channelId: item.channelId, // 栏目id
    title: item.title, // 标题
    subTitle: item.subTitle, // 副标题
    publishDate: item.publishDate ? item.publishDate.split(' ')[0].replace(/-/g, '.') : '',
    parentChannelNo: item.parentChannelNo, // 父栏目
    imgSrc: item.thumbImg ? item.thumbImg : getFirstImg(item.content),
    appImgSrc: item.thumbAppImg ?? '', // app缩略图，默认为'' 字符串作为 URI 进行编码
    content: item.content, // 详情
    $content: html2text(item.content),
    abstract: item.description, // 摘要内容
    source: item.source ?? '', // 文章来源
    linkUrl: item.linkUrl ?? '', // 自定义链接,
    isShowTitle: item.isShowTitle, // 是否显示标题
    visitCount: item.visitCount, // 访问次数
    favoriteCount: item.favoriteCount, // 点赞数
    starCount: item.starCount, // 收藏数
    orgId: item.orgId, // 单位id
    orgName: item.orgName // 单位名称
  };
}

/**
 * 格式化临展列表
 *
 * @export
 * @param {*} data
 */
export function exhListFilter(data) {
  return data.map(exhDetailFilter);
}

/**
 * 格式化临展详情
 *
 * @export
 * @param {*} data
 */
export function exhDetailFilter(item) {
  return {
    id: item.exhibitionTempId,
    groupId: item.groupId,
    title: item.tempName,
    content: item.description,
    startTime: item.startTime ? item.startTime.split(' ')[0] : '',
    endTime: item.endTime ? item.endTime.split(' ')[0] : '',
    exhDate:
      item.startTime.split(' ')[0].replace(/-/g, '.') +
      ' - ' +
      item.endTime.split(' ')[0].replace(/-/g, '.'),
    /* exhDate: showDateTime(
      item.startTime ? item.startTime.split(' ')[0] : '',
      item.endTime ? item.endTime.split(' ')[0] : ''
    ), */
    place: item.tempPlace,
    linkUrl: item.linkUrl,
    imgSrc: item.thumb ? item.thumb : '' || getFirstImg(item.description),
    appimgSrc: item.appThumb ? item.appThumb : '',
    orgId: item.orgId, // 单位id
    orgName: item.orgName, // 单位名称

    favoriteCount: item.favoriteCount, // 点赞数
    starCount: item.starCount, // 收藏数
    clickCount: item.clickCount // 查看数
  };
}
/**
 * 格式化常展列表
 *
 * @export
 * @param {*} data
 */
export function exhBaseListFilter(data) {
  return data.map(exhBaseDetailFilter);
}

/**
 * 格式化常展详情
 *
 * @export
 * @param {*} data
 */
export function exhBaseDetailFilter(item) {
  return {
    id: item.exhibitionBaseId,
    groupId: item.groupId,
    title: item.baseName,
    content: item.description,
    startTime: item.startTime ? item.startTime.split(' ')[0] : '',
    endTime: item.endTime ? item.endTime.split(' ')[0] : '',
    exhDate: showDateTime(
      item.startTime ? item.startTime.split(' ')[0] : '',
      item.endTime ? item.endTime.split(' ')[0] : ''
    ),
    linkUrl: item.linkUrl,
    place: item.basePlace,
    imgSrc: item.thumb ? item.thumb : '' || getFirstImg(item.description),
    appimgSrc: item.appThumb ? item.appThumb : ''
  };
}

/**
 * 格式化文件列表
 *
 * @export
 * @param {*} data
 */
export function fileFilter(data) {
  return data.map((item) => {
    return {
      id: item.fileInfoId,
      relateId: item.objectId,
      title: item.fileOldname.split('.')[0],
      type: item.fileSuffix,
      content: item.description,
      filePath: item.filePath ? assetsPrefix + item.filePath + item.fileName : ''
    };
  });
}

/**
 * 格式化藏品列表
 *
 * @export
 * @param {*} data
 */
export function colListFilter(data) {
  return data.map((item) => colDetailFilter(item));
}

/**
 * 格式化藏品详情
 *
 * @export
 * @param {*} data
 */
export function colDetailFilter(item) {
  return {
    id: item.exhibitId,
    title: item.exhibitName,
    type: item.exhibitType,
    modelUrl: item.modelUrl,
    linkUrl: item.modelUrl,
    level: item.exhibitLevel,
    age: item.age,
    size: item.size,
    content: item.description,
    unit: item.collectionUnit,
    groupId: item.groupId,
    explanationWord: item.explanationWord, // 讲解词
    imgSrc: item.thumb ? item.thumb.split(';')[0] : '' || getFirstImg(item.description),
    appimgSrc: item.appThumb ? item.appThumb : '',
    orgName: item.orgName,
    hdImg: item.boolean2,
    ...item
  };
}

/**
 * 格式化活动列表
 *
 * @export
 * @param {*} data
 */
export function actListFilter(data) {
  return data.map(actDetailFilter);
}

/**
 * 格式化活动详情
 *
 * @export
 * @param {*} data
 */
export function actDetailFilter(item) {
  return {
    id: item.activityId,
    groupId: item.groupId,
    title: item.activityName,
    acttype: item.activityType,
    place: item.activityVenueName,
    content: item.description,
    startTime: item.startTime ? moment(item.startTime).format('YYYY-MM-DD HH:mm') : '',
    endTime: item.endTime ? moment(item.endTime).format('YYYY-MM-DD HH:mm') : '',
    actDate: showDateTime(
      item.startTime ? moment(item.startTime).format('YYYY-MM-DD HH:mm') : '',
      item.endTime ? moment(item.endTime).format('YYYY-MM-DD HH:mm') : '',
      true
    ),
    reserveStartTime: item.reserveStartTime
      ? moment(item.reserveStartTime).format('YYYY-MM-DD HH:mm')
      : '',
    reserveEndTime: item.reserveEndTime
      ? moment(item.reserveEndTime).format('YYYY-MM-DD HH:mm')
      : '',
    reserveDate: showDateTime(
      item.reserveStartTime ? moment(item.reserveStartTime).format('YYYY-MM-DD HH:mm') : '',
      item.reserveEndTime ? moment(item.reserveEndTime).format('YYYY-MM-DD HH:mm') : '',
      true
    ),
    minAge: item.minAge,
    maxAge: item.maxAge,
    linkUrl: item.linkUrl,
    imgSrc: item.thumb ? item.thumb : '' || getFirstImg(item.description),
    appimgSrc: item.appThumb ? item.appThumb : '',
    orgId: item.orgId, // 单位id
    orgName: item.orgName, // 单位名称
    capacityNum: item.capacityNum, // 预约总数
    reserveNum: item.reserveNum,
    visitCount: item.visitCount,
    starCount: item.starCount,
    activityStatus: item.activityStatus,
    reserveType: item.reserveType,
    reserveStatus: item.reserveStatus
  };
}

/**
 * 格式化全文搜索列表
 *
 * @export
 * @param {*} data
 */
export function searchListFilter(data) {
  return data.map((item) => {
    let type = 0;
    switch (item.type) {
      case 'archives':
        type = 0;
        break;
      case 'activity':
        type = 1;
        break;
      case 'exhibit':
        type = 2;
        break;
      case 'exhibitionTemp':
        type = 3;
        break;
      case 'exhibitionBase':
        type = 4;
        break;
      default:
        break;
    }
    return {
      id: item.id, // 文章id
      channelId: item.channelId, // 栏目编号
      title: item.title, // 标题
      imgSrc: item.thumb, // 缩略图，默认为'' 字符串作为 URI 进行编码
      abstract: item.description, // 摘要内容
      content: item.content, // 详情
      linkUrl: item.linkUrl || '', // 自定义链接,
      type: type
    };
  });
}

export function leaveWordListFilter(data) {
  return data.map((item) => {
    return {
      id: item.messageId, // 留言id
      source: item.source, // 来源
      content: item.content, // 留言内容
      createTime: item.createTime, // 留言时间
      email: item.email, // 留言用户email
      name: item.name, // 留言用户姓名
      phoneNumber: item.phoneNumber, // 留言用户电话号码
      type: item.type, // 留言类型
      reply: item.replyMessage // 回复
    };
  });
}
export function exhibitionTypeFilter(data) {
  return data.map((item) => {
    return {
      id: item.exhibitTypeId,
      name: item.typeName,
      type: item.typeNo
    };
  });
}
export function textFilter(data) {
  let newdata = data && data?.replace(/®/g, '<sup>®</sup>');
  return newdata;
}
