/**
 * @file   数据读取
 * @create lixiang 2020/10/13
 * @update
 */
import service from './index';
import { mapDataToRoute, cmsDataProvider, RootRouteMap } from '@portal-site/data';
import { cms_gateway, https } from './http';

export const dataProvider = (http) => ({
  ...cmsDataProvider(http, ''),
  ...service(http, https),
  ...{
    queryRoutes: function() {
      // const endPoint = `/japi/${cms_gateway}/api/queryAllChannel`;
      const endPoint = `/${cms_gateway}/api/queryAllChannel`;
      return new Promise((resolve, reject) => {
        http
          .get(endPoint + '/CN')
          .then((res) => {
            const root = new RootRouteMap();
            const routeMaps = res.data.data.records.map((r) => {
              return mapDataToRoute(r, root);
            });
            root.children = routeMaps;
            resolve(root);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    }
  }
});
