import React, { lazy, Suspense } from 'react';
import Loading from '@/app/components/Loading';

/**
 * @description 异步加载组件HOC
 * @param {Component} LazyComponent - React.lazy包裹后的组件
 */
export const withLazyComponent = function(LazyComponent) {
  return function(props) {
    return (
      <Suspense fallback={<Loading />}>
        <LazyComponent {...props} />
      </Suspense>
    );
  };
};
const withLazyComponentData = (LazyComponent) => (data) => (props) => (
  <Suspense fallback={<Loading />}>
    <LazyComponent {...props} {...data} />
  </Suspense>
);

// 首页
export const AsyncHome = withLazyComponent(lazy(() => import('../pages/Home')));

// 产品和服务
export const AsyncProductsServices = withLazyComponent(
  lazy(() => import('../pages/ProductsServices'))
);

// 解决方案
export const AsyncSolution = withLazyComponent(lazy(() => import('../pages/Solution')));

// 关于我们
export const AsyncAbout = withLazyComponent(lazy(() => import('../pages/About')));

// 新闻动态
export const AsyncNews = withLazyComponent(lazy(() => import('../pages/News')));

// 其他
const RouteText = lazy(() => import('../common/TextList/RouteText'));
export const [S_RouteText] = [withLazyComponentData(RouteText)];

// 详情
const MainDetail = lazy(() => import('../common/MainDetail/MainDetail'));

export const [S_MainDetail] = [withLazyComponent(MainDetail)];
